import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import nookies from 'nookies';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import FeedsProvider from '/imports/job-tracking/api/context/feeds.context';
import Account from '/pages/account';
import BoardsProvider from '/imports/job-tracking/api/context/boards.context';
import { CURRENT_USER } from '/imports/core/api/apollo/client/queries';
import DashboardPage from '/imports/job-tracking/ui/pages/dashboard';
import Error from '/components/Error';
import GeneralProvider from '/imports/job-tracking/api/context/general.context';
import { getSearchJob } from '/imports/job-tracking/api/api';
import { getCountry } from '/imports/checkout/api/utils';
import HeaderLayoutSkeleton from '/imports/job-tracking/ui/skeleton/HeaderLayoutSkeleton';
import { isJobTrackEnable } from '/lib/helpers';
import JobTrackingDashboard from '/imports/job-tracking/ui/pages/JobTrackingDashboard';
import LayoutSkeleton from '/imports/job-tracking/ui/skeleton/layoutSkeleton';
import { AccountProvider as Provider } from '/imports/core/api/accounts/accountContext';
import SearchComp from '/imports/job-tracking/ui/pages/search';
import SearchV2 from 'imports/job-tracking/ui/pages/searchV2';

import SearchProvider from '/imports/job-tracking/api/context/search.context';
import TaskProvider from '/imports/job-tracking/api/context/taskContext';
import useIntl from '/imports/core/api/useIntl';
import { useResponsive } from '/imports/core/api/responsiveContext';
import { Push } from '/components/Link';
import { getIsAgent } from '/imports/generator/api/helpers';
import JobSearch from '/imports/job-tracking/ui/pages/JobSearch';
import SavedJobs from '/imports/job-tracking/ui/pages/SavedJobs';
import useFeedStore from 'zustand/feedStore';
import { useSearchStore } from 'zustand/SearchStore';
import appApi from 'imports/job-tracking/api/appApi';
import { useGeneralStore } from 'zustand/generalStore';
import PolishedResumes from 'imports/job-tracking/ui/pages/PolishedResumes';
import ResumesTracking from 'imports/job-tracking/ui/pages/ResumesTracking';
import PromptTesting from 'imports/job-tracking/ui/pages/PromptTesting';

const JobTrackingComponent = dynamic(() => import('/imports/job-tracking/ui/pages/JobTracking'), {
  ssr: false,
});
const Layout = dynamic(() => import('/imports/job-tracking/ui/layout/layout'), {
  ssr: false,
});

const HeaderLayout = dynamic(() => import('/imports/job-tracking/ui/layout/HeaderLayout'), {
  ssr: false,
});

const App = (props) => {
  useEffect(() => {
    //reload app on new account to make sure ApolloClient is also updated
    if (localStorage.getItem('reload_next')) {
      localStorage.removeItem('reload_next');
      window.location.reload();
    }
  }, []);
  const isAgent = getIsAgent();
  const { feeds } = useFeedStore();
  const { setactiveCard, setSearchId, setsearchData } = useSearchStore();
  const { Boards } = useGeneralStore();
  const { token, agent_client_id } = nookies.get();
  const { isMobile } = useResponsive();
  const [requestLoad, setRequestLoad] = useState(!!token && true);
  const [data, setData] = useState(null);
  const [searchData, setSearchData] = useState(null);
  const { locale } = useIntl();
  const { template, id, page, search, location } = props.router.query;
  const { state } = props?.router;
  const renderTemplate = template ? template : page;
  const isHeader = isJobTrackEnable() && !isMobile;
  const [podioJobs, setPodioJobs] = useState([]);
  const country = getCountry();
  const { useFeedList, usePodioSaveJobs } = appApi(token, props);
  const { isLoading, data: FeedData } = useFeedList(country);
  const { isLoading: podiJobLoading } = usePodioSaveJobs();

  const fetchIntialData = async () => {
    if (props.router.query?.reason && props.router.query?.reason === 'user-agent') {
      typeof localStorage !== 'undefined' && localStorage.setItem('isAgent', true);
    }
  };

  const fetchSearch = async () => {
    if (search || location) {
      setSearchData({ ...data, searchText: search ? search : '', cityText: location ? location : '' });
    }
    // if (search && location) {
    //   const obj = {
    //     title: search,
    //     where: location,
    //   };
    //   // const response = await getSearchJob(obj, token);
    //   // console.log('response', response);
    //   // setactiveCard(response[0]);
    //   // setSearchData({
    //   //   jobs: response,
    //   //   Loading: false,
    //   //   length: response.length,
    //   //   activeCard: response[0],
    //   //   searchText: obj.title,
    //   //   cityText: obj.where,
    //   // });
    //   return;
    // }
  };

  useEffect(() => {
    if (feeds && feeds.length > 0 && template === 'feeds' && id) {
      const isExist = feeds.find((h) => h.feedId === id);
      !isExist && Push('/app?template=search', locale, '/app/search');
    }
    fetchSearch();
    setSearchId(id);
    setsearchData(searchData);
  }, [feeds, id]);

  useEffect(() => {
    if (isJobTrackEnable() && !!token) {
      fetchIntialData();
    } else if (state && state?.asPath === '/app/dashboard' && !!token) {
      fetchIntialData();
    }
  }, []);

  const renderComponent = (loading) => {
    switch (renderTemplate) {
      case 'tracking':
        if (id) return <JobTrackingComponent boardId={id} />;
        return <JobTrackingDashboard />;
      case 'search':
        return <SearchComp />;
      case 'search-v2':
        return <SearchV2 />;
      case 'feeds':
        return <SearchComp feedId={id} loading={isLoading} />;
      case 'dashboard':
        return <DashboardPage {...props} />;
      case 'account':
        return <Account />;
      case 'saved-jobs':
        return <SavedJobs {...props} />;
      case 'job-search':
        return <JobSearch id={id} />;
      case 'polish-resumes':
        return <PolishedResumes />;
      case 'resumes-tracking':
        return <ResumesTracking />;
      case 'prompt-testing':
        return <PromptTesting />;
      default:
        return <SearchComp />;
    }
  };
  const headerExp = (requestLoad, isAgent) => {
    if (isHeader && !isAgent) {
      return <HeaderLayout requestLoad={requestLoad}>{renderComponent(requestLoad)}</HeaderLayout>;
    } else {
      return (
        <Layout page={renderTitle()} loading={false} podioJobLists={podioJobs} isListLoading={isLoading}>
          {renderComponent(requestLoad)}
        </Layout>
      );
    }
  };
  const renderTitle = () => {
    if (id) return id;
    return template;
  };
  const { loading, error, data: userData } = useQuery(CURRENT_USER, { skip: !token });
  if (!!token && loading) return isHeader ? <HeaderLayoutSkeleton /> : <LayoutSkeleton />;
  if (!!token && error) return <Error />;

  if (userData?.userInfo) {
    const isAgent = userData?.userInfo?.role === 'agent' || !!agent_client_id;
    const isExtensionUser =
      userData?.userInfo?.extensionOnboardOption && userData?.userInfo?.extensionOnboardOption !== 'skip';
    if (!(isAgent || isExtensionUser)) return Push('/resumes');
  }

  return (
    <Provider
      value={{
        currentUser: userData ? userData.userInfo : null,
        userLoading: loading,
      }}
    >
      <GeneralProvider fetchIntialData={fetchIntialData}>
        <BoardsProvider boards={Boards}>
          <TaskProvider>
            <FeedsProvider feedList={data?.feedList}>
              <SearchProvider feedId={id} podioJobs={podioJobs} data={searchData} setPodioJobs={setPodioJobs}>
                {headerExp(requestLoad, isAgent)}
              </SearchProvider>
            </FeedsProvider>
          </TaskProvider>
        </BoardsProvider>
      </GeneralProvider>
    </Provider>
  );
};

App.displayName = 'App';

export default App;

App.propTypes = {
  router: PropTypes.shape({
    query: PropTypes.object,
  }),
};
